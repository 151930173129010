import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  private readonly AUTH_TOKEN_KEY = 'auth_token';
  private readonly USER_DATA_KEY = 'user_data';

  constructor() {
    // Verificar se existe token salvo
    const token = localStorage.getItem(this.AUTH_TOKEN_KEY);
    if (token) {
      this.isAuthenticatedSubject.next(true);
    }
  }

  login(credentials: { email: string; password: string }): Observable<boolean> {
    // TODO: Implementar chamada real à API
    // Simulando uma chamada à API
    return of(true).pipe(
      tap(() => {
        // Simulando dados do usuário
        const userData = {
          id: 1,
          name: 'Usuário Teste',
          email: credentials.email,
        };

        // Salvar token e dados do usuário
        localStorage.setItem(this.AUTH_TOKEN_KEY, 'token_exemplo');
        localStorage.setItem(this.USER_DATA_KEY, JSON.stringify(userData));

        this.isAuthenticatedSubject.next(true);
      })
    );
  }

  logout(): void {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
    localStorage.removeItem(this.USER_DATA_KEY);
    this.isAuthenticatedSubject.next(false);
  }

  getUserData(): any {
    const userData = localStorage.getItem(this.USER_DATA_KEY);
    return userData ? JSON.parse(userData) : null;
  }

  isAuthenticated(): boolean {
    return this.isAuthenticatedSubject.value;
  }
}
